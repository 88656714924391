/* eslint-disable no-undef */
import React from "react"
import StructurePageCiva from "@components/pageCasosdeExito/template/templatePageCiva"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Civa [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/civa/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Entérate cómo Civa perfeccionó en un 95% sus tiempos de respuesta gracias a la automatización de sus canales de contacto." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Civa" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/civa/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/og-civa.png`}
      />
      <meta property="og:description" content="Entérate cómo Civa perfeccionó en un 95% sus tiempos de respuesta gracias a la automatización de sus canales de contacto." />
    </Helmet>
    <StructurePageCiva location={location} />
  </div>
)

export default IndexPage
